.root {
  background: #ebeced;
  border: 2px solid #9e0e33;
  border-radius: 4px;
  height: 80px;
  padding: 10px;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    font-size: 10pt;
    font-weight: bold;
    line-height: 1.25;
    color: #68676b;
    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 380px) {
  .root {
    padding: 8px;
    p {
      font-size: 9pt;
    }
  }
}
