$salmon: #deb67f;

@font-face {
  font-family: "Bodoni MT Italic";
  src: url("../../static/bodoni-mt-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.root {
  background: $salmon;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.logo {
  margin: 20px 0;
  width: 100%;
  height: 192px;
  text-align: center;

  &-round {
    display: block;
    margin: 0 auto;
    height: 112px;
    width: auto;
    margin-bottom: 10px;
  }

  &-text {
    display: block;
    margin: 0 auto;
    height: 70px;
  }
}

.sections {
  flex-grow: 1;
  width: 100%;
}

.section-link {
  height: 18vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #dedede;
  font-family: "Bodoni MT Italic";
  font-size: 36pt;
  background-size: cover;
  background-repeat: no-repeat;

  .section-link-title {
    line-height: 1;
  }

  &.entradas {
    background-image: url(../../static/entradas.png);
    .section-link-title {
      width: 225px;
    }
  }
  &.picadas {
    background-image: url(../../static/picadas.png);
  }
  &.platosFrios {
    background-image: url(../../static/platos-frios.png);
  }
  &.platosCalientes {
    background-image: url(../../static/pcalientes.png);
  }
  &.masaFilo {
    background-image: url(../../static/masa-filo.png);
    .section-link-title {
      width: 285px;
    }
  }
  &.cafeteria {
    background-image: url(../../static/cafeteria.png);
  }
  &.postres {
    background-image: url(../../static/postres.png);
  }
}

@media only screen and (max-width: 380px) {
  .section-link {
    font-size: 32pt;
  }
}
