.root {
  display: flex;
}

.details {
  flex-grow: 1;
  padding-right: 25px;
  font-family: "Helvetica Neue Light";

  h2 {
    font-family: "Avenir Next Condensed Italic";
    font-size: 12pt;
    font-weight: bold;
    color: #333333;
    margin: 0 0 5px;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 10pt;
    line-height: 1.4;
    margin: 0;
    color: #000000;
  }

  .title-subtext {
    display: inline;
    font-weight: normal;
    font-size: 10pt;
    margin-left: 4px;
  }

  .list-description {
    font-size: 10pt;
    line-height: 1.3;
  }

  .food-icons {
    display: inline-flex;
    /*margin: -2px 0 0 6px;*/
    margin: 5px 0 0;
  }
}

.price {
  align-self: center;
  background: #dfdfdf;
  border-radius: 4px;
  padding: 5px;
  line-height: 16px;
  font-size: 11pt;
  white-space: nowrap;

  &.double-price {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 9pt;

    span:first-child {
      border-bottom: 1px solid #666666;
    }

    span {
      white-space: nowrap;
    }
  }
}
