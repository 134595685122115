.icon {
  display: block;
  margin: 0 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  &:first-child {
    margin-left: 0;
  }
}

.icon-glutenFree {
  background-image: url("../../static/gluten-free.png");
}
.icon-vegan {
  background-image: url("../../static/vegan.png");
}
.icon-vegetarian {
  background-image: url("../../static/vegetarian.png");
}

.icon-size-small {
  height: 16px;
  width: 16px;
}
.icon-size-medium {
  height: 20px;
  width: 20px;
}
