.root {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ebeced;
  border: 2px solid #9e0e33;
  border-radius: 4px;
  padding: 10px;
  height: 80px;
  font-size: 10pt;
  font-weight: bold;
  color: #68676b;
  margin: 0;
}

.item {
  display: inline-flex;
  margin: 6px 0;
}

@media only screen and (max-width: 380px) {
  .root {
    font-size: 9pt;
    padding: 8px;
  }
}
