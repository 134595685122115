$salmon: #deb67f;

@font-face {
  font-family: "Helvetica Neue Light";
  src: url("../../static/HelveticaNeueLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Condensed Italic";
  src: url("../../static/Avenir-Next-LT-Pro-Condensed-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $salmon;
  height: 100vh;
}

.logo {
  margin: 20px 0;
  width: 98px;
}

.section-title {
  height: 84px;
  width: 100%;
  background-position: 50% 50%;
  background-size: 100%;
  font-family: "Bodoni MT Italic";
  font-size: 32pt;
  color: #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 22px;
  flex-shrink: 0;

  .section-title-inner {
    line-height: 1;
  }

  &.entradas {
    background-image: url("../../static/entradas.png");
  }
  &.picadas {
    background-image: url("../../static/picadas.png");
  }
  &.platosFrios {
    background-image: url("../../static/platos-frios.png");
  }
  &.platosCalientes {
    background-image: url("../../static/pcalientes.png");
  }
  &.masaFilo {
    background-image: url("../../static/masa-filo.png");
    height: 94px;
    .section-title-inner {
      width: 256px;
    }
  }
  &.cafeteria {
    background-image: url("../../static/cafeteria.png");
  }
  &.postres {
    background-image: url("../../static/postres.png");
  }
}

.section-content {
  background: #ffffff;
  margin: 0;
  padding: 24px 28px;
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;

  > ul {
    list-style-type: none;
    padding: 0;

    > li {
      margin-bottom: 20px;
    }
  }
}

.footer {
  background: #ffffff;
  margin: 30px 0 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 380px) {
  .section-title {
    font-size: 28pt;
  }
}
