.root {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.link {
  width: 100%;
  display: inline-flex;
  justify-content: center;
}

.background-image {
  object-fit: contain;
}
