.root {
  display: flex;
  align-items: center;
}

.text {
  font-family: "Helvetica Neue Light";
  font-size: 11pt;
  margin-right: 4px;
}
